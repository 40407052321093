/* Header.css */


nav {
    background-color: var(--dark-color);
    position: fixed;
    top: 0;
    z-index: 1000;

    display: flex;
    justify-content: space-evenly;

    width: 100%;
    height: 50px;
    padding: 10px 20px;
}

.hamburger-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
    margin: 0;
    padding: 10px 0;
}

/*styling for the menu items*/
nav span {
    color: var(--light-color);
    background-color: var(--dark-color);

    width: 100%;

    text-align: center;
    text-decoration: none;
    font-size: 36px;

    transition: text-decoration 0.5s ease; /* Smooth underline transition */
}

nav span:hover {
    color: var(--accent-color);
}

