@import "../App.css";

.image-container {
    display: flex;
    justify-content: center;
}

.offset-container{
    height: 1px;
    width: 220px;
}

.content-container {
    display: flex;
    justify-content: space-around; /* Distribute items evenly along the main axis */
    flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
    max-width: 1240px; /* Set a maximum width for larger screens */
    margin: 0 auto; /* Center the container horizontally */
    align-content: center;
}

.rounded-image {
    border-radius: 50%; /* Make the image rounded */
    width: 200px; /* Adjust the width as needed */
    height: 200px; /* Adjust the height as needed */
    object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
    margin: 20px;
}

.flex-row {
    flex-direction:row
}

.flex-column {
    flex-direction:column;
}

.ctaContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0 10px 0;

}

.ctaButton:hover{
    color: var(--dark-color);
    background-color: var(--accent-color);
    border-color: var(--dark-color);
    border-width: 4px;
}

.ctaButton{

    font-size: 28px;
    font-weight: bolder;
    background-color: var(--light-color);
    width: fit-content;
    padding: 10px 20px;
    border-radius: 90px;
    transition-duration: 0.5s;
    transition-delay: 0.1s;
    color: var(--dark-color);
    border-color: var(--accent-color);
    border-width: 4px;
    border-style: solid;
}
