.toolList {
    list-style: none;
    grid-gap: 0.5em;
    display: grid;
    gap: 0.5em;
    grid-auto-flow: column;
    grid-template-rows: min-content;
    overflow-x: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    padding:0;
}

.toolList::-webkit-scrollbar{
    display:none;
}

.toolLabel {
    color: var(--dark-color);
    background-color: var(--light-color);
    border-color: var(--accent-color);

    border-radius: 90px;
    border-style: solid;
    border-width: 3px;

    font-size: 28px;
    font-weight: bolder;

    padding: 10px 20px;
    width: fit-content;

    transition-delay: .1s;
    transition-duration: .5s;

    white-space: nowrap;
    scroll-snap-align: center;
}

.toolLabel:hover,.toolLabel:focus {
    color: var(--dark-color);
    background-color: var(--accent-color);
    border-color: var(--dark-color);

    border-width: 3px;

}

.toolCard{
    background-color: var(--light-card-color);
    border-radius: 10px;
    padding: 10px;
    max-width: 750px;
}

.toolContent{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
}

.toolLogo{
    background-color: var(--light-color);
    border-color: var(--dark-color);
    border-radius: 50px;

    height: 200px;
    width: fit-content;
    margin: 15px;
    padding: 10px;
}

.toolDesc{
    width: 450px
}
