/* Slogans.css */
.slogan {
    margin: 0;
    transform-origin: top;
    transition-duration: 1s;
    transition-delay: 1.5s;
    font-size: 3rem;
    text-align: center;
    padding: 20px;
}

.active{
    color: var(--accent-color);
    transform-origin: bottom;
    transition-duration: 1s;
    transition-delay: 1s;
    transition-property: color;
}

.sloganContainer {
        font-size: 3rem;
        text-align: center;
        display: block;
        font-weight: bold;
}