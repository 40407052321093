.ScrollDetectionFooter {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: fixed;
    bottom: 0;
    transform: translate(-50%);
    left: 50%;
    background-color: var(--light-color);
    width: 100%;
}