/* App.css */
@import url('https://fonts.googleapis.com/css?family=Figtree:regular,bold,italic&subset=latin,latin-ext');

:root {
    --dark-color: #202124;
    --light-color: #fafafa;
    --accent-color: #76a376;
    --dark-card-color: #292d2e;
    --light-card-color: #e1e1e1;
    --border-radius: 10px;
}

html {
    scroll-behavior: smooth;
    font-family: 'Figtree', serif;
}
section{
    padding: 20px;
}

h1{
    font-size: 5rem ;
    text-align: center;}

h2{
    font-size: 3rem;
    text-align: center;
}
h3{
    font-size: 2rem;
}

p{
    font-size: 1.5rem;
    text-align: left;
}

.information-container {
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 780px;
    width: 90%;
}

.content-container {
    margin-top: 70px; /* Adjust this margin to leave space for the fixed header */
    font-family: 'Figtree', serif;
}


.dark-background{
  background-color: var(--dark-color);
    color: var(--light-color);
}

.light-background{
    background-color: var(--light-color) ;
    color: var(--dark-color);
}

.scrolldetectorOffset{
    height: 150px;
}