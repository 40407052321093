.GalleryCard{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    max-width: 750px;

    background-color: var(--dark-card-color);
    border-radius: var(--border-radius);

    scroll-snap-align: center;

    height: fit-content;
}

.cardContent{
    display: flex;
    padding: 10px;
    align-content: center
}

.cardContent img{
    border-radius: 50px;
    height: 250px;
    width: fit-content;
    margin: 15px;
    padding: 10px;
    background-color: var(--light-color);
}

.description{
    font-size: 24px;
    margin: 10px;
    width: 400px;
}

.toolBox{
    align-items: center;
    background-color: var(--light-color);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
}

.tool {
    background-color: var(--accent-color);
    border-radius: 90px;
    padding: 5px;
    font-size: 24px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 5px;
    white-space: nowrap;
}
