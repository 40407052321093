.ImageGallery {
    gap: 3px;

    display: grid;
    grid-auto-flow: column;
    grid-template-rows: min-content;

    overflow-x: auto;
    overscroll-behavior-x: contain;

    scroll-snap-type: x mandatory;
}

.ImageGallery::-webkit-scrollbar{
    display:none;
}