.contactform{
  display: flex;
  flex-direction: column;
}
button{
  background-color: var(--dark-color);
  width: 100%;
  color: var(--light-color);
  border-radius: 10px;
  border: 2px;
  padding: 10px;
  font-size: 2rem;
  height: auto;
}

button:hover, button:focus{
    background-color: var(--accent-color);
    border-color: var(--accent-color);
}

.disableForm{
  z-index: 100;
  position: absolute;
  transition: transform 0.2s, font-size 0.2s;
  background-color: rgb(55, 55, 55);
  opacity: 60%;
  padding: 20px;
  height: 292px;
  transform: translate(-20px, -20px);
}

.ThankYou{
  opacity: 100%;
  color: var(--light-color);
  font-size: 30px;
  font-weight: bolder;
}