.input-container {
    position: relative;
    margin-bottom: 20px;
}

.multiline{
    min-height: 7.5em;
    resize: none;
}

input, textarea {
    width: 100%;
    padding: 10px;
    font-size: 24px;
    margin-top: 8px;
    border: 2px solid var(--accent-color);
    border-radius: 4px;
}

label {
    font-size: 20px;
    position: absolute;
    top: 22px;
    left: 10px;
    transition: transform 0.2s, font-size 0.2s;
    border-radius: 20px;
}

.input-container.focused label {
    background: var(--dark-color);
    color: var(--light-color);
    padding: 0 10px 3px;
    transform: translateY(-36px);
    font-size: 24px;
}